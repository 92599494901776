<template>
  <div class="register">
    <div class="title">
      <div class="back_icon" @click="handelBack()">
        <img src="@/assets/images/back_icon.png" alt="" />
      </div>
      完善信息
    </div>
    <div class="input_box">
      <div class="item">
        <input type="text" v-model="fullName" placeholder="请输入真实姓名" />
      </div>
      <div class="item" @click="handelItem('sex')">
        <input type="text" v-model="sex" readonly placeholder="性别" />
        <img src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item" @click="handelItem('identity')">
        <input type="text" v-model="identity" readonly placeholder="身份" />
        <img src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item">
        <input type="number" v-model="id" placeholder="请输入身份证号" />
      </div>
      <div class="item" @click="handelItem('school')">
        <input type="text" v-model="school" readonly placeholder="请选择机构/学校" />
        <img src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item" @click="handelItem('team')">
        <input type="text" v-model="team" readonly placeholder="请选择班级" />
        <img src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item">
        <input type="text" v-model="mail" placeholder="邮箱" />
      </div>
      <div class="ID_photo">
        <h3>上传身份证照片</h3>
        <div>
          <div class="left_box">
            <van-uploader v-model="fileListZ" :after-read="afterReadZheng" />
            <p>上传人像页照片</p>
          </div>
          <div class="right_box">
            <van-uploader v-model="fileListF" :after-read="afterReadFan" />
            <p>上传国徽页照片</p>
          </div>
        </div>
      </div>
      <div class="ID_photo photo_box">
        <h3>采集照片</h3>
        <div>
          <div class="left_box">
            <van-uploader v-model="fileListP" :after-read="afterReadPeo" />
            <p>拍摄照片</p>
          </div>
        </div>
      </div>
      <div class="register_btn" @click="handelRegister()">提交信息</div>
    </div>
    <!-- 性别 -->
    <van-popup v-model="showSex" position="bottom" :style="{ height: '42%' }">
      <van-picker
        show-toolbar
        :columns="columnsSex"
        @confirm="onConfirmSex"
        @cancel="onCancel('sex')"
      />
    </van-popup>
    <!-- 身份 -->
    <van-popup
      v-model="showIdentity"
      position="bottom"
      :style="{ height: '42%' }"
    >
      <van-picker
        show-toolbar
        :columns="columnsIdentity"
        @confirm="onConfirmIdentity"
        @cancel="onCancel('identity')"
      />
    </van-popup>
    <!-- 机构/学校 -->
    <van-popup
      v-model="showSchool"
      position="bottom"
      :style="{ height: '42%' }"
    >
      <van-picker
        show-toolbar
        :columns="columnsSchool"
        @confirm="onConfirmSchool"
        @cancel="onCancel('school')"
      />
    </van-popup>
    <!-- 班级 -->
    <van-popup v-model="showTeam" position="bottom" :style="{ height: '42%' }">
      <van-picker
        show-toolbar
        :columns="columnsTeam"
        @confirm="onConfirmTeam"
        @cancel="onCancel('team')"
      />
    </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import { Toast } from "vant";
import { getSchool, getTema, getUploadImages, getPerfectInfo } from "@/request/api";
export default {
  data() {
    return {
      fullName: "",
      sex: "",
      identity: "",
      id: "",
      school: "",
      team: "",
      mail: "",
      fileListZ: [],
      fileListF: [],
      fileListP: [],
      showSex: false,
      columnsSex: ["男性", "女性"],
      showIdentity: false,
      columnsIdentity: ["学生"],
      showSchool: false,
      columnsSchool: [],
      schoolId: 0,
      showTeam: false,
      columnsTeam: [],
      teamId: 0,
      idZhengSrc: "", 
      idFanSrc: "",
      idPeoSrc: ""
    };
  },
  components: {},
  mounted() {
    this.getSchool();
  },
  methods: {
    handelBack() {
      // this.$router.go(-1);
      localStorage.removeItem("authorization");
      localStorage.removeItem("loginUser");
      localStorage.removeItem("retUrl");
      this.$router.push("/Login");
    },

    // 确定性别
    onConfirmSex(value) {
      this.showSex = false;
      this.sex = value;
    },

    // 确定身份
    onConfirmIdentity(value) {
      this.showIdentity = false;
      this.identity = value;
    },

    // 确定机构/学校
    onConfirmSchool(e) {
      this.showSchool = false;
      this.school = e.text;
      this.schoolId = e.id;
      this.team = '';
      this.teamId = '';
      this.getTema(this.schoolId);
    },

    // 确定班级
    onConfirmTeam(e) {
      this.showTeam = false;
      this.team = e.text;
      this.teamId = e.id;
    },

    // 封装弹出层显示
    handelItem(item) {
      if (item == "sex") {
        this.showSex = true;
      } else if (item == "identity") {
        this.showIdentity = true;
      } else if (item == "school") {
        this.showSchool = true;
      } else if (item == "team") {
        console.log(1);
        this.showTeam = true;
      }
    },
    // 封装弹出层消失
    onCancel(item) {
      if (item == "sex") {
        this.showSex = false;
      } else if (item == "identity") {
        this.showIdentity = false;
      } else if (item == "school") {
        this.showSchool = false;
      } else if (item == "team") {
        this.showTeam = false;
      }
    },

    // 机构/学校接口
    getSchool() {
      getSchool().then((res) => {
        this.columnsSchool = this.setParamSchool(res.data);
      });
    },

    // 班级接口
    getTema(id) {
      console.log(id);
      getTema(id).then((res) => {
        console.log(res);
        this.columnsTeam = this.setParamTeam(res.data);
        if (this.columnsTeam.length == 0) {
          Toast(`暂无班级!`);
        }
      });
    },

    // 封装机构参数
    setParamSchool(data) {
      let columns = [];
      for (let item of data) {
        let param = {
          id: item.id,
          text: item.name,
        };
        columns.push(param);
      }
      return columns;
    },

    // 封装班级参数
    setParamTeam(data) {
      let columns = [];
      for (let item of data) {
        let param = {
          id: item.id,
          text: item.className,
        };
        columns.push(param);
      }
      return columns;
    },

    // 上传图片接口
    getUploadImages(type, param, file) {
      getUploadImages(param).then((res) => {
        file.status = "done";
        file.message = "上传成功";
        if (type == "Z") {
          this.idZhengSrc = res;
        } else if (type == "F") {
          this.idFanSrc = res;
        } else if (type == "P") {
          this.idPeoSrc = res;
        }
      });
    },

    // 上传身份证正面照片
    afterReadZheng(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let param = {
        base64: file.content,
      };
      this.getUploadImages("Z", param, file);
    },

    // 上传身份证反面照片
    afterReadFan(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let param = {
        base64: file.content,
      };
      this.getUploadImages("F", param, file);
    },

    // 上传人脸照片
    afterReadPeo(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let param = {
        base64: file.content,
      };
      this.getUploadImages("P", param, file);
    },

    // 验证是否为空
    isEmptyVal(val, type) {
      if (!val) {
        Toast(`${type}不能为空!`);
        return false;
      } else {
        return true;
      }
    },

    // 点击注册
    handelRegister() {
      if (
        this.isEmptyVal(this.fullName.trim(), "真实姓名") &&
        this.isEmptyVal(this.sex, "性别") &&
        this.isEmptyVal(this.identity, "身份") &&
        this.isEmptyVal(this.id, "身份证号") &&
        this.isEmptyVal(this.school, "机构/学校") &&
        this.isEmptyVal(this.team, "班级") &&
        this.isEmptyVal(this.mail, "邮箱")
      ) {
        console.log(1);
        console.log(this.idZhengSrc);
        console.log(this.idFanSrc);
        console.log(this.idPeoSrc);
        let param = {
          realName: this.fullName.trim(),
          gender: this.sex == '男性' ? 1 : 2,
          role: 5,
          cardNum: this.id,
          institutionId: this.schoolId,
          classId: this.teamId,
          email: this.mail.trim(),
          cardHead: this.idZhengSrc,
          cardBack: this.idFanSrc,
          portrait: this.idPeoSrc,
        }
        this.getPerfectInfo(param)
      }
    },

    // 完善信息接口
    getPerfectInfo(param) {
      getPerfectInfo(param).then(res => {
        if(res) {
          Toast(`完善成功，请重新登录`);
          setTimeout(() => {
            localStorage.removeItem("authorization");
            localStorage.removeItem("loginUser");
            localStorage.removeItem("retUrl");
            this.$router.push("/Login");
          }, 1500)
        }
      })
    },
  },
  
};
</script>

<style scoped lang="less">
.register {
  padding-bottom: 0.5rem;
  .title {
    width: 100%;
    height: 0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PingFangSC-Regular";
    font-size: 0.34rem;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0067b0;
    z-index: 99;
  }

  .back_icon {
    position: absolute;
    left: 0.45rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .back_icon img {
    width: 0.19rem;
    height: 0.34rem;
  }

  .input_box {
    width: 100%;
    padding: 0 0.3rem;
    margin-top: 1.09rem;

    .item {
      border-bottom: 0.01rem solid #e5e5e5;
      padding: 0.3rem 0;
      margin-bottom: 0.1rem;
      position: relative;

      input {
        font-size: 0.32rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        width: 100%;
        font-size: 0.32rem;
      }

      input ::-webkit-input-placeholder {
        color: #999999;
      }

      img {
        width: 0.14rem;
        height: 0.25rem;
        position: absolute;
        right: 0.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .ID_photo {
    h3 {
      font-size: 0.32rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #0067b0;
      margin: 0.39rem 0;
    }

    & > div {
      display: flex;

      .left_box {
        /deep/ .van-uploader__upload {
          width: 3.3rem;
          height: 1.9rem;
          background-image: url("../assets/images/zheng.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      .right_box {
        /deep/ .van-uploader__upload {
          width: 3.3rem;
          height: 1.9rem;
          background-image: url("../assets/images/bei.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      /deep/ .van-uploader__upload-icon {
        display: none;
      }

      /deep/ .van-uploader__preview {
        position: absolute;
        z-index: 10;
      }

      /deep/ .van-uploader__preview-image {
        width: 3.3rem;
        height: 1.9rem;
      }

      /deep/ .van-uploader__preview-image img {
        object-fit: inherit !important;
      }

      p {
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin: 0.2rem 0 0.1rem 0;
      }
    }
  }

  .photo_box {
    & > div {
      display: flex;

      .left_box {
        /deep/ .van-uploader__upload {
          width: 1.81rem;
          height: 1.91rem;
          background-image: url("../assets/images/photo.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        /deep/ .van-uploader__preview-image {
          width: 1.81rem;
          height: 1.91rem;
        }

        /deep/ .van-uploader__preview-image img {
          object-fit: inherit !important;
        }
      }
    }
  }

  .input_box .obtain {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #bbbbbb;
    padding: 0.1rem 0.06rem;
    border: 0.01rem solid #bbbbbb;
    border-radius: 0.6rem;
  }

  .register_btn {
    width: 100%;
    height: 0.9rem;
    background: #0067b0;
    border-radius: 0.45rem;
    text-align: center;
    line-height: 0.9rem;
    font-size: 0.34rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #ffffff;
    margin-top: 1.5rem;
  }
}

/deep/ .van-picker__cancel {
  font-size: 0.32rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #999999;
}

/deep/ .van-picker__confirm {
  font-size: 0.32rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #0067b0;
}

/deep/ .van-picker-column__item {
  color: #333333;
  height: 0.88rem;
  font-size: 0.3rem;
}
</style>
